import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState
} from 'react';

const initialState = {
    initialized: false
};


const handlers = {
}


const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);


const UIContext = createContext({
    ...initialState
});


function UIProvider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <UIContext.Provider
            value={{
                ...state
            }}>
            {children}
        </UIContext.Provider>
    );

}

const useUI = () => useContext(UIContext);

export {
    UIContext,
    UIProvider,
    useUI
};