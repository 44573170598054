import { Router } from './routes/Router';

function InDevelopmentPage() {
  return (
    <div className='in-development'>
      <div>
        <div>
          <img src='https://cdn.pixabay.com/photo/2017/06/20/08/12/maintenance-2422173_1280.png' />
        </div>
        <div>
          <h1>WEBSITE IS UNDER CONSTRUCTION<span>!!!</span></h1>
        </div>
        <div>
          <div>
            <p>For any queries and etc, please contact us at -</p>
          </div>
          <div>
            <a href='mailto:contact@weborigin.in'>contact@weborigin.in</a>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <InDevelopmentPage />
      {/* <Router /> */}
    </>
  );
}

export default App;
