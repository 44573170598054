import { useMemo } from "react";
import { useCookies } from "react-cookie";
// import axiosInstance from "../api/helper";

const Axios = ({ children }) => {
    const [cookies] = useCookies([]);

    // useMemo(() => {
    //     axiosInstance.interceptors.request.use(
    //         (config) => {
    //             // const token = cookies[CookiesList.accessToken];
    //             // if (token) {
    //                 // config.headers['Authorization'] = getBearerTokenFormat(token);
    //             // }
    //             return config;
    //         },
    //         (error) => {
    //             return Promise.reject(error);
    //         },
    //     );

    // }, []);

    return children;
};

export default Axios;
