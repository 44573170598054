import { createContext, useContext, useEffect, useReducer } from "react";
import { useCookies } from 'react-cookie';

const initialState = {
    initialized: false
}

const handlers = {
    INITIALIZE: (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true
        }
    },
    DATA: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState
});


function AuthProvider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [cookies, setCookie, removeCookie] = useCookies([]);

    var req_controllers = {
        me: null
    };

    useEffect(() => {

        console.log("initializing authctx");
        
        return () => { };

    }, []);

    return (
        <AuthContext.Provider
            value={{
                ...state
            }}>
            {children}
        </AuthContext.Provider>
    )

}

const useAuth = () => useContext(AuthContext);

export {
    AuthContext,
    AuthProvider,
    useAuth
};